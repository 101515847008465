import React, {useState, useEffect} from "react";
import axios from "axios";

export const useGetProduct =  (url) =>{
    const [products, setProducts] = useState([]);
    useEffect(()=>{  axios.get(url).then((response)=>{
        setProducts(response.data);
    })},[])
  return products;
}

export const useGetProductCategory =  (url, category) =>{
    const [products, setProducts] = useState([]);
    useEffect(()=>{  axios.get(url).then((response)=>{
        setProducts(response.data);
    })},[category]);
  return products;
}

export const addProduct =  (url, data) =>{
    return  new  Promise((resolve, reject) => {
    axios.post(url,{data
    },{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response);
    }).catch((error)=>{
    })
})
}

export const updateProduct =  (url, data) =>{
    return  new  Promise((resolve, reject) => {
     axios.post(url,{data
    },{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response);
    }).catch((error)=>{
    })
})
}

export const deleteProduct = async (url) =>{
    axios.post(url)
    .then(() => window.location.reload(false));
}
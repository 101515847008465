import React from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import AdminItemMenu from "../../../components/adminItemMenu/AdminItemMenu";
import Button from "../../../components/button/Button";
import { Link } from "react-router-dom";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";
import { useGetClient } from "../../../Api/Clients";

const InfoClients = () => {
  const { id } = useParams();
  const clients = useGetClient(
    `${MAIN_API_KEY}/client/getSingleClient.php/?id=${id}`
  );
  const clientInfo = [
    { title: "Nombre", info: "Nombre" },
    { title: "Telefono", info: "Telefono" },
    { title: "Correo", info: "Correo" },
  ];
  return (
    <div className="adminLogin">
      <Adminheader title="Clientes" />
      {clients.length === 0 ? (
        <ClipLoader color="#CEA168" loading size={40} />
      ) : (
        <>
          <AdminItemMenu
            text="Cliente"
            itemType="clientInfo"
            clientName={clients.clientName}
            clientPhone={clients.clientPhone}
            clientMail={clients.clientMail}
            textSec="información del cliente"
          />
          <Link to={`/mensajesClientes/${id}`}>
            <Button text="Ver mensajes del cliente" nameClass="btnBig" />
          </Link>
        </>
      )}
    </div>
  );
};

export default InfoClients;

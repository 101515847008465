import React, {useState, useEffect} from "react";
import axios from "axios";

export const useGetFrontPageProducts =  (url) =>{
    const [products, setProducts] = useState([]);

    useEffect(()=>{  axios.get(url).then((response)=>{
        setProducts(response.data);
    })},[])
  return products;
}

export const addFrontPage =  (url, data) =>{
  return  new  Promise((resolve, reject) => {
   axios.post(url,{data},{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
    resolve(response);
  }).catch((error)=>{
      console.log(error);
  })
})
}

export const deleteFrontPage = async (url) =>{
  axios.post(url)
  .then(() => window.location.reload(false));
}
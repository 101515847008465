import React, { useState, useEffect } from "react";
import GppBadIcon from "@mui/icons-material/GppBad";
import { useNavigate } from "react-router-dom";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Button from "../../../components/button/Button";
import { useGetProduct } from "../../../Api/Products";
import { useGetFrontPageProducts, addFrontPage } from "../../../Api/FrontPage";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const HeroProductsAdd = () => {
  const [produrcId, setProdurcId] = useState("");
  const [frontItem, setFrontItem] = useState({});
  const [showError, setShowError] = useState(false);
  const navigation = useNavigate();
  const products = useGetProduct(`${MAIN_API_KEY}/products/read.php`);
  const frontPageProducts = useGetFrontPageProducts(
    `${MAIN_API_KEY}/frontPage/readFrontPage.php`
  );

  const filterProdructs = products.filter(
    (product) =>
      !frontPageProducts.find(
        ({ idProduct }) => product.idProducts === idProduct
      )
  );

  const getValueSeleted = (e) => {
    const { name, value } = e.target;
    setProdurcId(value);
  };

  const addFrontItem = () => {
    if (produrcId == 0) {
      setShowError(true);
    } else {
      addFrontPage(
        `${MAIN_API_KEY}/frontPage/createFrontPage.php`,
        frontItem
      ).then((response) => {
        console.log(response);
        if (response.data === 200) {
          navigation(-1);
        }
      });
    }
  };

  useEffect(() => {
    setFrontItem({
      idProduct: produrcId,
      frontImg: "",
      frontType: "producto",
    });
  }, [produrcId]);

  return (
    <div className="adminLogin">
      <Adminheader title="Agregar Producto" />
      <div className="input">
        <div className="inputTitle">Nombre del producto</div>
        <select className="inputNormal" onChange={getValueSeleted}>
          <option value="0">Seleccione un producto</option>
          {filterProdructs.map((product, index) => {
            return (
              <option key={index} value={product.idProducts}>
                {product.productName}
              </option>
            );
          })}
        </select>
      </div>
      <div onClick={addFrontItem}>
        <Button text="Agregar Producto" nameClass="btnBig" />
      </div>
      <div className={showError ? "warning true" : "warning"}>
        <GppBadIcon />
        Selecciona un producto valido
      </div>
    </div>
  );
};

export default HeroProductsAdd;

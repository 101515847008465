import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import TopProducts from "../topPoducts/TopProducts";
import Footer from "../footer/Footer";
import Input from "../input/Input";
import Button from "../button/Button";
import { useParams } from "react-router-dom";
import { addMessage } from "../../Api/Messages";
import GppBadIcon from "@mui/icons-material/GppBad";
import { MAIN_API_KEY } from "../../Api/API_KEYS";
import ClipLoader from "react-spinners/ClipLoader";

const ContactForm = () => {
  const [productName, setProductName] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientMail, setClientMail] = useState("");
  const [message, setMessage] = useState("");
  const [idProduct, setIdProduct] = useState("");
  const [showError, setShowError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [fullMessage, setFullMessage] = useState({});
  const productNameRef = useRef(null);
  const clientNameRef = useRef(null);
  const clientPhoneRef = useRef(null);
  const clientMailRef = useRef(null);
  const messageRef = useRef(null);
  const { producto, id } = useParams();

  const saveProductName = (e) => {
    const { name, value } = e.target;
    setProductName(value);
  };
  const saveClientName = (e) => {
    const { name, value } = e.target;
    setClientName(value);
  };
  const saveClientPhone = (e) => {
    const { name, value } = e.target;
    setClientPhone(value);
  };
  const saveClientMail = (e) => {
    const { name, value } = e.target;
    setClientMail(value);
  };
  const saveMessage = (e) => {
    const { name, value } = e.target;
    setMessage(value);
  };
  const sendMessage = () => {
    setSpinner(true);
    setShowError(false);
    if (
      clientName === "" ||
      clientPhone === "" ||
      clientMail === "" ||
      message === ""
    ) {
      setShowError(true);
      setSpinner(false);
    } else {
      addMessage(`${MAIN_API_KEY}/message/createMessage.php`, fullMessage).then(
        (response) => {
          if (response === 200) {
            setSpinner(false);
            window.location.reload(false);
          } else {
            setSpinner(false);
          }
        }
      );
    }
  };
  useEffect(() => {
    if (producto === "") {
      setProductName("");
      setIdProduct("");
      productNameRef.current.value = "";
    } else {
      productNameRef.current.value = producto;
      setProductName(producto);
      setIdProduct(id);
    }
  }, []);
  useEffect(() => {
    setFullMessage({
      idProduct: idProduct,
      productName: productName,
      clientName: clientName,
      clientPhone: clientPhone,
      clientMail: clientMail,
      message: message,
    });
  }, [productName, clientName, clientPhone, clientMail, message]);
  return (
    <div className="contactForm">
      <Header />
      <div className="contactForm-cont">
        <div className="input">
          <div className="inputTitle">Producto</div>
          <input
            type="text"
            name="productName"
            className="inputNormal"
            placeholder="Producto"
            autoComplete="off"
            ref={productNameRef}
            onChange={saveProductName}
          />
        </div>
        <div className="input">
          <div className="inputTitle">Nombre</div>
          <input
            type="text"
            name="clintName"
            className="inputNormal"
            placeholder="Nombre"
            autoComplete="off"
            ref={clientNameRef}
            onChange={saveClientName}
          />
        </div>
        <div className="input">
          <div className="inputTitle">Telefono</div>
          <input
            type="text"
            name="clientPhone"
            className="inputNormal"
            placeholder="Telefono"
            autoComplete="off"
            ref={clientPhoneRef}
            onChange={saveClientPhone}
          />
        </div>
        <div className="input">
          <div className="inputTitle">Correo</div>
          <input
            type="mail"
            name="clientMail"
            className="inputNormal"
            placeholder="Correo"
            autoComplete="off"
            ref={clientMailRef}
            onChange={saveClientMail}
          />
        </div>
        <div className="input">
          <div className="inputTitle">Mensaje</div>
          <input
            type="text"
            name="message"
            className="inputNormal"
            placeholder="Mensaje"
            autoComplete="off"
            ref={messageRef}
            onChange={saveMessage}
          />
        </div>
        <div className={showError ? "warning true" : "warning"}>
          <GppBadIcon />
          Asegúrate de haber rellenado todos los campos.
        </div>
        {spinner ? (
          <>
            <ClipLoader color="#CEA168" loading size={40} />
            Actualizando producto
          </>
        ) : (
          ""
        )}
        <div onClick={sendMessage}>
          <Button text="Pedir Informes" nameClass="btnBig" />
        </div>
      </div>

      <TopProducts />
      <Footer />
    </div>
  );
};

export default ContactForm;

import React from "react";
import { useParams, Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import AdminItemMenu from "../../../components/adminItemMenu/AdminItemMenu";
import Button from "../../../components/button/Button";
import { useGetProduct } from "../../../Api/Products";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const MainProducts = (props) => {
  let { categoria } = useParams();
  const products = useGetProduct(
    `${MAIN_API_KEY}/products/readByCategory.php/?categoria=${categoria}`
  );
  return (
    <div className="adminLogin">
      <Adminheader title={categoria} />
      <Link to={`/agregarProductos/${categoria}`}>
        <Button text="Agregar producto" nameClass="btnMenu" />
      </Link>
      {products.length === 0 ? (
        <ClipLoader color="#CEA168" loading size={40} />
      ) : products === false ? (
        "Aún no hay productos registrados en esta categoría"
      ) : (
        products.map((product, index) => {
          return (
            <div key={index}>
              <AdminItemMenu
                text={product.productName}
                itemId={product.idProducts}
                type="product"
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default MainProducts;

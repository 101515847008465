import React, { useEffect, useState, useRef } from "react";
import GppBadIcon from "@mui/icons-material/GppBad";
import ClipLoader from "react-spinners/ClipLoader";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Button from "../../../components/button/Button";
import { useParams, useNavigate } from "react-router-dom";
import { useGetCategories, updateCategory } from "../../../Api/Categories";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const EditCategory = (props) => {
  const navigation = useNavigate();
  const [newCategory, setNewCategory] = useState("");
  const [showError, setShowError] = useState(false);
  const [categoryImg, setCategoryImg] = useState(null);
  const [error, setError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  let { id } = useParams();
  const url = `${MAIN_API_KEY}/category`;
  const category = useGetCategories(url + `/getSingleCategory.php/?id=${id}`);
  const categoryRef = useRef(null);
  const categoryImgRef = useRef(null);

  const saveCategory = (e) => {
    const { name, value } = e.target;
    setNewCategory(value);
  };
  const UpdateCategory = () => {
    let data = {};
    if (newCategory == "" && categoryImg === null) {
      setShowError(true);
    } else {
      setSpinner(true);
      setShowError(false);

      if (categoryImg === null) {
        data = {
          categoryName:
            newCategory === "" ? category.categoryName : newCategory,
          categoryImg: "",
        };
        updateCategory(
          `${url}/updateCategory.php/?id=${category.idCategory}`,
          data
        ).then((response) => {
          if (response.data === 200) {
            setSpinner(false);
            navigation(-1);
          } else {
            setSpinner(false);
            setError(true);
            setShowError(true);
          }
        });
      } else {
        data = {
          categoryName:
            newCategory === "" ? category.categoryName : newCategory,
          categoryImg: categoryImg,
        };
        updateCategory(
          `${url}/updateCategory.php/?id=${category.idCategory}`,
          data
        ).then((response) => {
          if (response.data === 200) {
            setSpinner(false);
            navigation(-1);
          } else {
            setSpinner(false);
            setError(true);
            setShowError(true);
          }
        });
      }
    }
  };

  const saveCategoryImg = (e) => {
    setCategoryImg(e.target.files[0]);
  };

  useEffect(() => {
    if (category.categoryName != undefined) {
      categoryRef.current.value = category.categoryName;
    }
  }, [category]);

  return (
    <div className="adminLogin">
      <Adminheader title="Editar Categoría" />
      <div className="input">
        <div className="inputTitle">Nombre de la categoría</div>
        <input
          type="text"
          name="category"
          className="inputNormal"
          placeholder="Categoría"
          autoComplete="on"
          ref={categoryRef}
          onChange={saveCategory}
        />
      </div>
      <div className="input">
        <div className="inputTitle">
          {`Imagen de la Categoría`}
          <br />
          {`\( formatos png, jpg, jpeg o webp\)`}
        </div>
        <input
          type="file"
          name="categoryImg"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={categoryImgRef}
          onChange={saveCategoryImg}
        />
        <div className="inputImgCont">
          <img
            className="inputImg"
            src={`https://mikehr704.com/luna/newMethod/category/${category.categoryImg}`}
            alt="Imagen de Categoría"
          />
        </div>
      </div>
      <div onClick={UpdateCategory}>
        <Button text="Editar Categoría" nameClass="btnBig" />
      </div>
      {spinner ? (
        <>
          <ClipLoader color="#CEA168" loading size={40} />
          Actualizando Categoría
        </>
      ) : (
        ""
      )}
      <div className={showError ? "warning true" : "warning"}>
        <GppBadIcon />
        {error
          ? "Verifica que el formato de la imagen sea jpg, jpeg, png o webp"
          : "Primero debes actualizar la categoría"}
      </div>
    </div>
  );
};

export default EditCategory;

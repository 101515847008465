import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import Title from "../title/Title";
import TopProductItem from "./TopProductItem";
import { useGetProduct } from "../../Api/Products";
import { useGetFrontPageProducts } from "../../Api/FrontPage";
import { MAIN_API_KEY } from "../../Api/API_KEYS";

const TopProducts2 = () => {
  const [finalProduct, setFInalProduct] = useState([]);
  const frontPageProducts = useGetFrontPageProducts(
    `${MAIN_API_KEY}/topProduct/readTopProduct.php`
  );
  const products = useGetProduct(`${MAIN_API_KEY}/products/read.php`);
  const filterProdructs = products.filter((product) =>
    frontPageProducts.find(({ idProduct }) => product.idProducts === idProduct)
  );
  let size = filterProdructs.length;
  useEffect(() => {
    products.map((product) => {
      const newProducts = frontPageProducts.map((frontProduct) => {
        if (product.idProducts === frontProduct.idProduct) {
          setFInalProduct((current) => [
            ...current,
            {
              idProducts: product.idProducts,
              productDescription: product.productDescription,
              productPrice: product.productPrice,
              productImg1: product.productImg1,
              productName: product.productName,
              haveDiscount: product.haveDiscount,
              productDiscount: product.productDiscount,
              initDiscountDate: product.initDiscountDate,
              finalDiscountDate: product.finalDiscountDate,
              idFront: frontProduct.idTopProduct,
            },
          ]);
        }
      });
    });
  }, [size]);
  return (
    <div className="topProducts">
      <Title text="Productos Top" kind="underLineText" />
      <Swiper
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {finalProduct.map((product, index) => {
          return (
            <SwiperSlide key={index}>
              <TopProductItem
                id={product.idProducts}
                name={product.productName}
                description={product.productDescription}
                price={product.productPrice}
                img={product.productImg1}
                discount={product.haveDiscount}
                discountPor={product.productDiscount}
                initDate={product.initDiscountDate}
                finalDate={product.finalDiscountDate}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default TopProducts2;

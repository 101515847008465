import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import AdminItemMenu from "../../../components/adminItemMenu/AdminItemMenu";
import Button from "../../../components/button/Button";
import ClipLoader from "react-spinners/ClipLoader";
import { useGetFrontPageProducts } from "../../../Api/FrontPage";
import { useGetProduct } from "../../../Api/Products";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const HeroProductsMain = () => {
  const [finalProduct, setFInalProduct] = useState([]);

  const frontPageProducts = useGetFrontPageProducts(
    `${MAIN_API_KEY}/frontPage/readFrontPage.php`
  );
  const products = useGetProduct(`${MAIN_API_KEY}/products/read.php`);

  const filterProdructs = products.filter((product) =>
    frontPageProducts.find(({ idProduct }) => product.idProducts === idProduct)
  );

  const adds = frontPageProducts.filter((product) => {
    return product.frontType == "anuncio";
  });

  let size = filterProdructs.length + adds.length;

  useEffect(() => {
    products.map((product) => {
      const newProducts = frontPageProducts.map((frontProduct) => {
        if (product.idProducts === frontProduct.idProduct) {
          setFInalProduct((current) => [
            ...current,
            {
              productName: product.productName,
              idFront: frontProduct.idFrontPage,
            },
          ]);
        }
      });
    });
  }, [size]);

  return (
    <div className="adminLogin">
      <Adminheader title="Portada" />
      {size < 5 ? (
        <>
          <Link to="/portadaAgregar">
            <Button text="Agregar Producto" nameClass="btnMenu" />
          </Link>
          <Link to="/portadaAgregarAnuncio">
            <Button text="Agregar Anuncio" nameClass="btnMenu" />
          </Link>
        </>
      ) : (
        ""
      )}
      {finalProduct.map((product, index) => {
        if (product.frontType == "anuncio") {
        } else {
          return (
            <div key={index}>
              <AdminItemMenu
                text={product.productName}
                itemType="info"
                textSec="Eliminar de Portada"
                idFront={product.idFront}
                table="frontPage"
              />
            </div>
          );
        }
      })}
      {frontPageProducts.map((product, index) => {
        if (product.frontType == "anuncio") {
          return (
            <div key={index}>
              <AdminItemMenu
                text={product.frontType}
                itemType="info"
                textSec="Eliminar de Portada"
                idFront={product.idFrontPage}
                table="frontPage"
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default HeroProductsMain;

import React, { useState } from "react";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetProduct, useGetProductCategory } from "../../Api/Products";
import { MAIN_API_KEY } from "../../Api/API_KEYS";
import ClipLoader from "react-spinners/ClipLoader";

const IndividualProduct = () => {
  const [image, setImage] = useState("");
  const { id } = useParams();
  const url = `${MAIN_API_KEY}/products/`;
  const product = useGetProductCategory(
    url + `getSingleProduct.php/?id=${id}`,
    id
  );
  const financial = (num) => {
    return Number.parseFloat(num).toFixed(2);
  };
  const changeImage = (img) => {
    if (img === `${url}`) {
      setImage("../../../logos/luna.png");
    } else {
      setImage(img);
    }
  };
  const calculateDiscount = (price, discount) => {
    let dis = (discount / 100) * price;
    return price - dis;
  };
  const compareDate = (initDate, finalDate) => {
    if (initDate && finalDate) {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let actualDate = new Date(year, month, day);
      let initDateArr = initDate.split("-");
      let initDay = initDateArr[2];
      let initMonth = initDateArr[1];
      let initYear = initDateArr[0];
      let finalDateArr = finalDate.split("-");
      let finalDay = finalDateArr[2];
      let finalMonth = finalDateArr[1];
      let finalYear = finalDateArr[0];
      let initActualDate = new Date(initYear, initMonth, initDay);
      let finalActualDate = new Date(finalYear, finalMonth, finalDay);
      if (actualDate >= initActualDate && actualDate <= finalActualDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {product.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ClipLoader color="#CEA168" loading size={40} />
        </div>
      ) : (
        <div className="individualProduct">
          <div className="individualProduct-imgCont">
            <div className="individualProduct-imgColumn">
              <div
                className="individualProduct-imgColumn__itemCont"
                onClick={() => {
                  changeImage(`${url}${product.productImg1}`);
                }}
              >
                <img
                  className="individualProduct-imgColumn__item"
                  src={
                    product.productImg1 === ""
                      ? "../../../logos/luna.png"
                      : `${url}${product.productImg1}`
                  }
                  alt="product"
                />
              </div>
              <div
                className="individualProduct-imgColumn__itemCont"
                onClick={() => {
                  changeImage(`${url}${product.productImg2}`);
                }}
              >
                <img
                  className="individualProduct-imgColumn__item"
                  src={
                    product.productImg2 === ""
                      ? "../../../logos/luna.png"
                      : `${url}${product.productImg2}`
                  }
                  alt="product"
                />
              </div>
              <div
                className="individualProduct-imgColumn__itemCont"
                onClick={() => {
                  changeImage(`${url}${product.productImg3}`);
                }}
              >
                <img
                  className="individualProduct-imgColumn__item"
                  src={
                    product.productImg3 === ""
                      ? "../../../logos/luna.png"
                      : `${url}${product.productImg3}`
                  }
                  alt="product"
                />
              </div>
              <div
                className="individualProduct-imgColumn__itemCont"
                onClick={() => {
                  changeImage(`${url}${product.productImg4}`);
                }}
              >
                <img
                  className="individualProduct-imgColumn__item"
                  src={
                    product.productImg4 === ""
                      ? "../../../logos/luna.png"
                      : `${url}${product.productImg4}`
                  }
                  alt="product"
                />
              </div>
            </div>
            <div className="individualProduct-imgShow">
              <img
                className="individualProduct-imgShow__item"
                src={image !== "" ? image : `${url}${product.productImg1}`}
                alt="product"
              />
            </div>
          </div>
          <div className="individualProduct-infoCont">
            <div className="individualProduct-category">
              {product.productCategory}
            </div>
            <div className="individualProduct-title">{product.productName}</div>
            {compareDate(
              product.initDiscountDate,
              product.finalDiscountDate
            ) ? (
              <>
                <div className="individualProduct-priceCross">
                  ${financial(product.productPrice)}
                </div>
                <div className="individualProduct-price">
                  $
                  {financial(
                    calculateDiscount(
                      product.productPrice,
                      product.productDiscount
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="individualProduct-price">
                ${financial(product.productPrice)}
              </div>
            )}

            <div className="individualProduct-description">
              {product.productDescription}
            </div>
            <Link
              to={`/formularioContacto/${product.productName}/${product.idProducts}`}
              className="individualProduct-btn"
            >
              <Button text="Pedir Informes" nameClass="btnBig" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualProduct;

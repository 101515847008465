import React, { useEffect } from "react";
import "../assets/styles/mainStyle.scss";
import Header from "../components/Header/Header";
import CategorySection from "../components/categorySection/CategorySection";
import NewProducts from "../components/products/NewProducts";
import NewProducts2 from "../components/products/NewProducts2";
import TopProducts from "../components/topPoducts/TopProducts";
import TopProducts2 from "../components/topPoducts/TopProducts2";
import Footer from "../components/footer/Footer";

function Home() {
  useEffect(() => {
    document.title = "Luna Joyería";
  }, []);

  return (
    <div>
      <Header />
      <CategorySection />
      <NewProducts2 />
      <TopProducts2 />
      <Footer />
    </div>
  );
}

export default Home;

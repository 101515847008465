import React, {useEffect, useState } from "react";

export const useGetWindowSize = () => {
    const [size, setSize] = useState("");
    const getWindowSize = ()=>{
        setSize(window.innerWidth);
    }
    useEffect(() => {
      getWindowSize()
      }, []);
      window.addEventListener("resize", getWindowSize);
    return size;
  };

  export const useGetHeightSize = () => {
    const [height, setHeight] = useState(false);
   if(window.screenY >= 400){
    setHeight(true);
   }else{
    setHeight(false)
   }
    return height;
  };
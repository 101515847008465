import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Title from "../title/Title";
import ProductItem from "./ProductItem";
import { MAIN_API_KEY } from "../../Api/API_KEYS";
import { useGetProduct } from "../../Api/Products";
import { useGetWindowSize } from "../../hooks";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";

const NewProducts2 = () => {
  const products = useGetProduct(`${MAIN_API_KEY}/products/read.php`);
  const windowSize = useGetWindowSize();
  return (
    <div className="newProducts">
      <Title text="Nuevos Productos" kind="inLineText" />
      <Swiper
        slidesPerView={windowSize < 540 ? 1 : windowSize < 942 ? 2 : 3}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {products
          .slice(0)
          .reverse()
          .map((product, index) => {
            if (index < 21) {
              return (
                <SwiperSlide key={index}>
                  <ProductItem
                    id={product.idProducts}
                    name={product.productName}
                    description={product.productDescription}
                    price={product.productPrice}
                    discount={product.haveDiscount}
                    discountPor={product.productDiscount}
                    initDate={product.initDiscountDate}
                    finalDate={product.finalDiscountDate}
                    img={`${MAIN_API_KEY}/products/${product.productImg1}`}
                  />
                </SwiperSlide>
              );
            }
          })}
      </Swiper>
    </div>
  );
};

export default NewProducts2;

import React from "react";
import Button from "../button/Button";
import { useGetWindowSize } from "../../hooks";
import { Link } from "react-router-dom";
import { MAIN_API_KEY } from "../../Api/API_KEYS";
const TopProductItem = ({
  id,
  name,
  description,
  price,
  img,
  initDate,
  finalDate,
  discount,
  discountPor,
}) => {
  const windowWidth = useGetWindowSize();
  const url = `${MAIN_API_KEY}/products/`;
  const financial = (num) => {
    return Number.parseFloat(num).toFixed(2);
  };
  const calculateDiscount = (price, discount) => {
    let dis = (discount / 100) * price;
    return price - dis;
  };
  const compareDate = (initDate, finalDate) => {
    if (initDate && finalDate) {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let actualDate = new Date(year, month, day);
      let initDateArr = initDate.split("-");
      let initDay = initDateArr[2];
      let initMonth = initDateArr[1];
      let initYear = initDateArr[0];
      let finalDateArr = finalDate.split("-");
      let finalDay = finalDateArr[2];
      let finalMonth = finalDateArr[1];
      let finalYear = finalDateArr[0];
      let initActualDate = new Date(initYear, initMonth, initDay);
      let finalActualDate = new Date(finalYear, finalMonth, finalDay);
      if (actualDate >= initActualDate && actualDate <= finalActualDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="topProductItem">
      <div className="topProductItem-imgCont">
        <img
          className="topProductItem-imgCont__img"
          src={`${url}${img}`}
          alt="TopProduct"
        />
      </div>
      <div className="topProductItem-infoCont">
        <div className="topProductItem-infoCont__title">{name}</div>
        <div className="topProductItem-infoCont__descr">{description}</div>
        {compareDate(initDate, finalDate) ? (
          <>
            <div className="topProductItem-infoCont__priceCross">
              ${financial(price)}
            </div>
            <div className="topProductItem-infoCont__price">
              ${financial(calculateDiscount(price, discountPor))}
            </div>
          </>
        ) : (
          <div className="topProductItem-infoCont__price">
            ${financial(price)}
          </div>
        )}

        <Link to={`/productoIndividual/${id}`}>
          <Button
            text="Ver producto"
            nameClass={windowWidth >= "768" ? "btnBig" : "btnSmall"}
          />
        </Link>
      </div>
    </div>
  );
};

export default TopProductItem;

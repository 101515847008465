import React from "react";
import Slider from "./Slider";
import SlideMain from "./SlideMain";

const Hero = () => {
  return (
    <div className="hero">
      <div className="heroCont">
        <SlideMain />
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Adminheader = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="adminHeader">
      <Link to="/adminMenu">
        <img
          src="../../../logos/luna.png"
          alt="Logo"
          className="adminHeader-logo"
        />
      </Link>
      <div className="adminHeader-title">
        <button
          className="adminHeader-title__backBtn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
        </button>
        {title}
      </div>
    </div>
  );
};

export default Adminheader;

import React, {useState, useEffect} from "react";
import axios from "axios";

export const useGetClient =  (url) =>{
    const [clients, setClients] = useState([]);
    useEffect(()=>{  axios.get(url).then((response)=>{
        setClients(response.data);
    })},[])
  return clients;
}

export const addCLient =  (url, data) =>{
    return  new  Promise((resolve, reject) => {
    axios.post(url,{data
    },{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response);
    }).catch((error)=>{
    })
})
}

export const updateCLient =  (url, data) =>{
    return  new  Promise((resolve, reject) => {
     axios.post(url,{data
    },{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response);
    }).catch((error)=>{
    })
})
}

export const deleteCLient = async (url) =>{
    axios.post(url)
    .then(() => window.location.reload(false));
}
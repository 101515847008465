import React, { useEffect, useState } from "react";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import AdminItemMenu from "../../../components/adminItemMenu/AdminItemMenu";
import Button from "../../../components/button/Button";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { useGetCategories, deleteCategory } from "../../../Api/Categories";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const MainCategory = () => {
  const url = `${MAIN_API_KEY}/category`;
  const categories = useGetCategories(url + "/readCategory.php");

  return (
    <div className="adminLogin">
      <Adminheader title="Categorías" />
      <Link to="/agregarCategoria">
        <Button text="Agregar Categoría" nameClass="btnMenu" />
      </Link>
      {categories.length != 0 ? (
        categories.map((category, index) => {
          return (
            <div key={index}>
              <AdminItemMenu
                text={category.categoryName}
                itemId={category.idCategory}
                urlCate={url}
              />
            </div>
          );
        })
      ) : (
        <ClipLoader color="#CEA168" loading size={40} />
      )}
    </div>
  );
};

export default MainCategory;

import React from "react";
import Title from "../title/Title";
import ClipLoader from "react-spinners/ClipLoader";
import CategoryItem from "./CategoryItem";
import { useGetWindowSize } from "../../hooks";
import { MAIN_API_KEY } from "../../Api/API_KEYS";
import { useGetCategories } from "../../Api/Categories";

const CategorySection = () => {
  const url = `${MAIN_API_KEY}/category`;
  const categories = useGetCategories(url + "/readCategory.php");
  const size = useGetWindowSize();
  return (
    <div className="categorySec">
      <Title text="Categorías" kind="inLineText" />
      {categories.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ClipLoader color="#CEA168" loading size={40} />
        </div>
      ) : (
        <div className="categorySecCont">
          <div className="cateegoryItemCont">
            {categories.map((category, index) => {
              if (index < 4) {
                return (
                  <div key={index}>
                    <CategoryItem
                      text={category.categoryName}
                      image={category.categoryImg}
                      gradient={"linear" + (index + 1)}
                      kind={(index + 1) % 2 === 0 ? "reverse" : ""}
                    />
                  </div>
                );
              }
            })}
          </div>

          {size >= "768" ? (
            <div className="lateralCont">
              <img
                className="lateralImage"
                src={`${MAIN_API_KEY}/extraImages/back.jpg`}
                alt="image"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default CategorySection;

import React, {useState, useEffect} from "react";
import axios from "axios";

export const useGetCategories =  (url) =>{
    const [categories, setCategories] = useState([]);

    useEffect(()=>{  axios.get(url).then((response)=>{
        setCategories(response.data);
    })},[])
  return categories;
}

export const addCategory =  (url, data,) => {
    return  new  Promise((resolve, reject) => {
         axios.post(url,{data},{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{ 
            resolve(response);    
         }).catch((error)=>{
             console.log(error);
         })
    })
 }

export const updateCategory =  (url, data) => {
    return  new  Promise((resolve, reject) => {
    axios.post(url,{data
    }, {headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response);
    }).catch((error)=>{
        console.log(error);
    })
})
}

export const deleteCategory = async (url) =>{
    axios.post(url)
    .then((response) =>{ 
        window.location.reload(false)})
}

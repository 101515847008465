import React from "react";

/*
btnBig
btnSmall
*/
const Button = ({ text, nameClass }) => {
  return <div className={nameClass}>{text}</div>;
};

export default Button;

import React, {useState, useEffect} from "react";
import axios from "axios";

export const useGetMessage =  (url) =>{
    const [messages, setMessages] = useState([]);
    useEffect(()=>{  axios.get(url).then((response)=>{
        setMessages(response.data);
    })},[])
  return messages;
}

export const addMessage =  (url, data) =>{
    return  new  Promise((resolve, reject) => {
    axios.post(url,{data
    },{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response.data);
    }).catch((error)=>{
    })
})
}

export const updateMessage =  (url, data) =>{
    return  new  Promise((resolve, reject) => {
     axios.post(url,{data
    },{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
        resolve(response);
    }).catch((error)=>{
    })
})
}

export const deleteMessage = async (url) =>{
    axios.post(url)
    .then(() => window.location.reload(false));
}
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useGetWindowSize } from "../../hooks";
import { useGetCategories } from "../../Api/Categories";
import { MAIN_API_KEY } from "../../Api/API_KEYS";

const NavBar = () => {
  const url = `${MAIN_API_KEY}/category`;
  const categories = useGetCategories(url + "/readCategory.php");
  const size = useGetWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const [height, setHeight] = useState(false);
  const changeMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const getHeightSize = () => {
    if (window.scrollY >= 400) {
      setHeight(true);
    } else {
      setHeight(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", getHeightSize);
  }, []);

  return (
    <div className="navBar">
      {size >= "768" ? (
        <ul className={height ? "navBar-cont change" : "navBar-cont"}>
          <li
            className={
              height ? "navBar-cont__item change" : "navBar-cont__item"
            }
          >
            <Link to="/">Inicio</Link>
          </li>
          <li
            className={
              height ? "navBar-cont__item change" : "navBar-cont__item"
            }
          >
            Categorías <KeyboardArrowDownIcon />
            <ul className="navBar-contSecondary">
              {categories.map((category, index) => {
                return (
                  <li key={index}>
                    <Link to={`/productos/${category.categoryName}`}>
                      {" "}
                      {category.categoryName}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
          <li
            className={
              height ? "navBar-cont__item change" : "navBar-cont__item"
            }
          >
            <Link to="/">
              <img src="../../../logos/luna.png" alt="LunaLogo" />
            </Link>
          </li>
          <li
            className={
              height ? "navBar-cont__item change" : "navBar-cont__item"
            }
          >
            <Link to="/productosDescuento">Descuentos</Link>
          </li>
          <li
            className={
              height ? "navBar-cont__item change" : "navBar-cont__item"
            }
          >
            <Link to="/formularioContacto">Contacto</Link>
          </li>
        </ul>
      ) : (
        <div className={showMenu ? "navBar-cont changeColors" : "navBar-cont"}>
          <Link to="/">
            <img src="../../../logos/lunaLeft.png" alt="LunaLogo" />
          </Link>
          {showMenu ? (
            <CloseIcon
              style={{ fontSize: "36px", cursor: "pinter" }}
              onClick={changeMenu}
            />
          ) : (
            <MenuIcon
              style={{ fontSize: "36px", cursor: "pinter" }}
              onClick={changeMenu}
            />
          )}

          <div
            className={showMenu ? "navBar-contMenu show" : "navBar-contMenu"}
          >
            <ul className="navBar-contMenu__Menu">
              <li className="navBar-cont__item">
                <Link to="/">Inicio</Link>
              </li>
              <li className="navBar-cont__item">
                Categorías <KeyboardArrowDownIcon />
                <ul className="navBar-contSecondary" style={{ zIndex: "5" }}>
                  {categories.map((category, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/productos/${category.categoryName}`}>
                          {" "}
                          {category.categoryName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="navBar-cont__item move">
                <Link to="/productosDescuento">Descuentos</Link>
              </li>
              <Link to="/formularioContacto">Contacto</Link>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;

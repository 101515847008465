import React from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import AdminItemMenu from "../../../components/adminItemMenu/AdminItemMenu";
import { useGetMessage } from "../../../Api/Messages";
import { useGetClient } from "../../../Api/Clients";
import { useGetProduct } from "../../../Api/Products";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const MessagesClient = () => {
  const { id } = useParams();
  const messages = useGetMessage(
    `${MAIN_API_KEY}/message/readMessageByDate.php/?id=${id}`
  );
  const clients = useGetClient(
    `${MAIN_API_KEY}/client/getSingleClient.php/?id=${id}`
  );
  const clientInfo = [
    { title: "Nombre", info: "Nombre" },
    { title: "Telefono", info: "Telefono" },
    { title: "Correo", info: "Correo" },
    { title: "Fecha", info: "Fecha" },
    { title: "Producto", info: "Producto" },
    { title: "Mensaje", info: "Mensaje" },
  ];
  return (
    <div className="adminLogin">
      <Adminheader title="Mensajes" />
      {messages.length === 0 ? (
        <ClipLoader color="#CEA168" loading size={40} />
      ) : (
        messages.map((message, index) => {
          return (
            <div key={index}>
              <AdminItemMenu
                text="Cliente"
                itemType="clientInfoMessage"
                clientName={clients.clientName}
                clientPhone={clients.clientPhone}
                clientMail={clients.clientMail}
                clientProduct={message.idProduct}
                clientMessage={message.message}
                textSec="información del cliente"
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default MessagesClient;

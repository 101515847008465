import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../button/Button";
import { useGetWindowSize } from "../../hooks";
import { useGetFrontPageProducts } from "../../Api/FrontPage";
import { useGetProduct } from "../../Api/Products";
import { MAIN_API_KEY } from "../../Api/API_KEYS";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
const SlideMain = () => {
  const [finalProduct, setFInalProduct] = useState([]);
  const urlProd = `${MAIN_API_KEY}/products/`;
  const urlFront = `${MAIN_API_KEY}/frontPage/`;
  const frontPageProducts = useGetFrontPageProducts(
    `${MAIN_API_KEY}/frontPage/readFrontPage.php`
  );
  const products = useGetProduct(`${MAIN_API_KEY}/products/read.php`);

  const filterProdructs = products.filter((product) =>
    frontPageProducts.find(({ idProduct }) => product.idProducts === idProduct)
  );

  const adds = frontPageProducts.filter((product) => {
    return product.frontType == "anuncio";
  });

  let size2 = filterProdructs.length + adds.length;

  const calculateDiscount = (price, discount) => {
    let dis = (discount / 100) * price;
    return price - dis;
  };
  const compareDate = (initDate, finalDate) => {
    if (initDate && finalDate) {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let actualDate = new Date(year, month, day);
      let initDateArr = initDate.split("-");
      let initDay = initDateArr[2];
      let initMonth = initDateArr[1];
      let initYear = initDateArr[0];
      let finalDateArr = finalDate.split("-");
      let finalDay = finalDateArr[2];
      let finalMonth = finalDateArr[1];
      let finalYear = finalDateArr[0];
      let initActualDate = new Date(initYear, initMonth, initDay);
      let finalActualDate = new Date(finalYear, finalMonth, finalDay);
      if (actualDate >= initActualDate && actualDate <= finalActualDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    products.map((product) => {
      const newProducts = frontPageProducts.map((frontProduct) => {
        if (product.idProducts === frontProduct.idProduct) {
          setFInalProduct((current) => [
            ...current,
            {
              idProducts: product.idProducts,
              productDescription: product.productDescription,
              productPrice: product.productPrice,
              productImg1: product.productImg1,
              productName: product.productName,
              haveDiscount: product.haveDiscount,
              productDiscount: product.productDiscount,
              initDiscountDate: product.initDiscountDate,
              finalDiscountDate: product.finalDiscountDate,
              idFront: frontProduct.idTopProduct,
            },
          ]);
        }
      });
    });
  }, [size2]);

  const size = useGetWindowSize();

  const financial = (num) => {
    return Number.parseFloat(num).toFixed(2);
  };

  return (
    <>
      <Swiper
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {filterProdructs.map((product, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="slider">
                <div className="sliderText-cont">
                  <div className="sliderText-title">{product.productName}</div>
                  {compareDate(
                    product.initDiscountDate,
                    product.finalDiscountDate
                  ) ? (
                    <>
                      <div className="sliderText-priceCross">
                        ${financial(product.productPrice)}
                      </div>
                      <div className="sliderText-price">
                        $
                        {financial(
                          calculateDiscount(
                            product.productPrice,
                            product.productDiscount
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="sliderText-price">
                      ${financial(product.productPrice)}
                    </div>
                  )}

                  {size >= "768" ? (
                    <Link to={`/productoIndividual/${product.idProducts}`}>
                      <Button text="Ver Prdducto" nameClass="btnBig" />
                    </Link>
                  ) : (
                    <Link to={`/productoIndividual/${product.idProducts}`}>
                      <Button text="Ver Prdducto" nameClass="btnSmall" />
                    </Link>
                  )}
                </div>
                <dir className="slider-div"></dir>
                <img
                  className="sliderImg"
                  src={`${urlProd}${product.productImg1}`}
                  alt="LunaLogo"
                />
              </div>
            </SwiperSlide>
          );
        })}
        {frontPageProducts.map((product, index) => {
          if (product.frontType === "anuncio") {
            return (
              <SwiperSlide key={index}>
                <div className="slider">
                  <dir className="slider-div"></dir>
                  <img
                    className="sliderImg"
                    src={`${urlFront}${product.frontImg}`}
                    alt="LunaLogo"
                  />
                </div>
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </>
  );
};

export default SlideMain;

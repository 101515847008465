import React, { useEffect } from "react";
import Header from "../components/Header/Header";
import TopProducts from "../components/topPoducts/TopProducts";
import Footer from "../components/footer/Footer";
import Title from "../components/title/Title";
import ProductItem from "../components/products/ProductItem";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetProduct, useGetProductCategory } from "../Api/Products";
import { MAIN_API_KEY } from "../Api/API_KEYS";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Pagination } from "swiper";
import "./styles.css";
import { useGetWindowSize } from "../hooks";
import ClipLoader from "react-spinners/ClipLoader";

const GeneralProducts = () => {
  const windowSize = useGetWindowSize();
  const { categoria } = useParams();
  const products2 = useGetProductCategory(
    `${MAIN_API_KEY}/products/readByCategory.php/?categoria=${categoria}`,
    categoria
  );

  useEffect(() => {}, [categoria]);
  return (
    <div>
      <Header />
      <Title kind="inLineText" text={categoria} />
      <div className="generalContainer">
        {products2.length === 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ClipLoader color="#CEA168" loading size={40} />
          </div>
        ) : (
          <Swiper
            slidesPerView={windowSize < 550 ? 2 : 3}
            grid={{
              rows: 2,
            }}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            modules={[Grid, Pagination]}
            className="mySwiper"
          >
            {products2 === false
              ? "No hay productos en esta categoría"
              : products2.map((product, index) => {
                  return (
                    <SwiperSlide className="swiperItem" key={index}>
                      <ProductItem
                        id={product.idProducts}
                        name={product.productName}
                        description={product.productDescription}
                        price={product.productPrice}
                        discount={product.haveDiscount}
                        discountPor={product.productDiscount}
                        initDate={product.initDiscountDate}
                        finalDate={product.finalDiscountDate}
                        img={`${MAIN_API_KEY}/products/${product.productImg1}`}
                      />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        )}
      </div>
      <TopProducts />
      <Footer />
    </div>
  );
};

export default GeneralProducts;

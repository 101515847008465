import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import AdminItemMenu from "../../../components/adminItemMenu/AdminItemMenu";
import Button from "../../../components/button/Button";
import { useGetFrontPageProducts } from "../../../Api/FrontPage";
import { useGetProduct } from "../../../Api/Products";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const TopProdutsMain = () => {
  const [finalProduct, setFInalProduct] = useState([]);
  const frontPageProducts = useGetFrontPageProducts(
    `${MAIN_API_KEY}/topProduct/readTopProduct.php`
  );
  const products = useGetProduct(`${MAIN_API_KEY}/products/read.php`);
  const filterProdructs = products.filter((product) =>
    frontPageProducts.find(({ idProduct }) => product.idProducts === idProduct)
  );
  let size = filterProdructs.length;

  useEffect(() => {
    products.map((product) => {
      const newProducts = frontPageProducts.map((frontProduct) => {
        if (product.idProducts === frontProduct.idProduct) {
          setFInalProduct((current) => [
            ...current,
            {
              productName: product.productName,
              idFront: frontProduct.idTopProduct,
            },
          ]);
        }
      });
    });
  }, [size]);

  return (
    <div className="adminLogin">
      <Adminheader title="Productos Destadados" />
      <Link to="/productosDestacadosAgregar">
        <Button text="Agregar Producto" nameClass="btnMenu" />
      </Link>
      {finalProduct.map((product, index) => {
        return (
          <div key={index}>
            <AdminItemMenu
              text={product.productName}
              itemType="info2"
              textSec="Eliminar producto"
              idFront={product.idFront}
              table="topProduct"
            />
          </div>
        );
      })}
    </div>
  );
};

export default TopProdutsMain;

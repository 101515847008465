import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import GppBadIcon from "@mui/icons-material/GppBad";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import {
  useGetProduct,
  updateProduct,
  deleteProduct,
} from "../../../Api/Products";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const EditProducts = () => {
  const { id } = useParams();
  const url = `${MAIN_API_KEY}/products/`;
  const product = useGetProduct(url + `getSingleProduct.php/?id=${id}`);
  const navigation = useNavigate();
  const [newProduct, setNewProduct] = useState({});
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImg1, setProductImg1] = useState("");
  const [productImg2, setProductImg2] = useState("");
  const [productImg3, setProductImg3] = useState("");
  const [productImg4, setProductImg4] = useState("");
  const [haveDiscount, setHaveDiscount] = useState("");
  const [productDiscount, setProductDiscount] = useState("");
  const [initDiscountDate, setInitDiscountDate] = useState("");
  const [finalDiscountDate, setFinalDiscountDate] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const productNameRef = useRef(null);
  const productCategoryRef = useRef(null);
  const productDescriptionRef = useRef(null);
  const productPriceRef = useRef(null);
  const productImg1Ref = useRef(null);
  const productImg2Ref = useRef(null);
  const productImg3Ref = useRef(null);
  const productImg4Ref = useRef(null);
  const haveDiscountRef = useRef(null);
  const productDiscountRef = useRef(null);
  const initDiscountDateRef = useRef(null);
  const finalDiscountDateRef = useRef(null);
  useEffect(() => {
    if (product.productName != undefined) {
      productNameRef.current.value = product.productName;
      setProductName(product.productName);
      productCategoryRef.current.value = product.productCategory;
      productDescriptionRef.current.value = product.productDescription;
      setProductDescription(product.productDescription);
      productPriceRef.current.value = product.productPrice;
      setProductPrice(product.productPrice);
      productDiscountRef.current.value = product.productDiscount;
      setProductDiscount(product.productDiscount);
      initDiscountDateRef.current.value = product.initDiscountDate;
      setInitDiscountDate(product.initDiscountDate);
      finalDiscountDateRef.current.value = product.finalDiscountDate;
      setFinalDiscountDate(product.finalDiscountDate);
      haveDiscountRef.current.value = product.haveDiscount;
      setHaveDiscount(product.haveDiscount);
    }
  }, [product]);
  const saveProduct = () => {
    setSpinner(true);
    updateProduct(`${url}updateProduct.php/?id=${id}`, newProduct).then(
      (response) => {
        if (response.data === 200) {
          setSpinner(false);
          navigation(-1);
        } else {
          setSpinner(false);
          setShowError(true);
        }
      }
    );
  };

  const saveProductName = (e) => {
    const { name, value } = e.target;
    setProductName(value);
  };
  const saveProductDescription = (e) => {
    const { name, value } = e.target;
    setProductDescription(value);
  };
  const saveProductPrice = (e) => {
    const { name, value } = e.target;
    setProductPrice(value);
  };
  const saveProductDiscount = (e) => {
    const { name, value } = e.target;
    setProductDiscount(value);
  };
  const saveInitDiscountDate = (e) => {
    const { name, value } = e.target;
    setInitDiscountDate(value);
  };
  const saveFinalDiscountDate = (e) => {
    const { name, value } = e.target;
    setFinalDiscountDate(value);
  };
  const saveProductImg1 = (e) => {
    setProductImg1(e.target.files[0]);
  };
  const saveProductImg2 = (e) => {
    setProductImg2(e.target.files[0]);
  };
  const saveProductImg3 = (e) => {
    setProductImg3(e.target.files[0]);
  };
  const saveProductImg4 = (e) => {
    setProductImg4(e.target.files[0]);
  };
  const saveHaveDiscount = (e) => {
    const { name, value } = e.target;
    setHaveDiscount(value);
  };

  useEffect(() => {
    let img1;
    let img2;
    let img3;
    let img4;

    if (productImg1) {
      img1 = productImg1;
    } else {
      img1 = product.productImg1;
    }
    if (productImg2) {
      img2 = productImg2;
    } else {
      img2 = product.productImg2;
    }
    if (productImg3) {
      img3 = productImg3;
    } else {
      img3 = product.productImg3;
    }
    if (productImg4) {
      img4 = productImg4;
    } else {
      img4 = product.productImg4;
    }
    setNewProduct({
      idProducts: id,
      productName: productName,
      productCategory: product.productCategory,
      productDescription: productDescription,
      productPrice: productPrice,
      productDiscount: productDiscount,
      initDiscountDate: initDiscountDate,
      finalDiscountDate: finalDiscountDate,
      productImg1: img1,
      productImg2: img2,
      productImg3: img3,
      productImg4: img4,
      haveDiscount: haveDiscount,
    });
  }, [
    productName,
    productDescription,
    productPrice,
    productDiscount,
    initDiscountDate,
    finalDiscountDate,
    productImg1,
    productImg2,
    productImg3,
    productImg4,
    haveDiscount,
  ]);

  return (
    <div className="adminLogin">
      <Adminheader title="Editar Producto" />
      {product.length === 0 ? (
        <ClipLoader color="#CEA168" loading size={40} />
      ) : (
        <>
          <div className="input">
            <div className="inputTitle">Nombre del producto</div>
            <input
              type="text"
              name="productName"
              className="inputNormal"
              placeholder="Producto"
              autoComplete="off"
              ref={productNameRef}
              onChange={saveProductName}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Categoría del producto</div>
            <input
              type="text"
              name="productCategory"
              className="inputNormal"
              placeholder="Categoría"
              autoComplete="off"
              ref={productCategoryRef}
              readOnly={true}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Descripción del producto</div>
            <input
              type="text"
              name="productDescription"
              className="inputNormal"
              placeholder="Descripción"
              autoComplete="off"
              ref={productDescriptionRef}
              onChange={saveProductDescription}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Precio del producto</div>
            <input
              type="text"
              name="productPrice"
              className="inputNormal"
              placeholder="Precio"
              autoComplete="off"
              ref={productPriceRef}
              onChange={saveProductPrice}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Activar Descuento</div>
            <select
              ref={haveDiscountRef}
              className="inputNormal"
              onChange={saveHaveDiscount}
            >
              <option value="false">Inactivo</option>
              <option value="true">Activo</option>
            </select>
          </div>
          <div className="input">
            <div className="inputTitle">Descuento del producto</div>
            <input
              type="text"
              name="productDiscount"
              className="inputNormal"
              placeholder="Precio"
              autoComplete="off"
              ref={productDiscountRef}
              onChange={saveProductDiscount}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Fecha de inicio del descuento</div>
            <input
              type="date"
              name="initiDiscountDate"
              className="inputNormal"
              placeholder="Fecha de inicio"
              autoComplete="off"
              ref={initDiscountDateRef}
              onChange={saveInitDiscountDate}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Fecha final del descuento</div>
            <input
              type="date"
              name="finalDiscountDate"
              className="inputNormal"
              placeholder="Fecha Final"
              autoComplete="off"
              ref={finalDiscountDateRef}
              onChange={saveFinalDiscountDate}
            />
          </div>
          <div className="input">
            <div className="inputTitle">Fotos del producto</div>
            <input
              type="file"
              name="productImg1"
              className="inputNormal"
              placeholder="Precio"
              autoComplete="off"
              ref={productImg1Ref}
              onChange={saveProductImg1}
            />
            {product.productImg1 === "" ? (
              <div>"No hay imagen registrada"</div>
            ) : (
              <div className="inputImgCont">
                <img
                  className="inputImg"
                  src={`${url}${product.productImg1}`}
                  alt="Imagen de Categoría"
                />
              </div>
            )}
          </div>
          <div className="input">
            <input
              type="file"
              name="productImg2"
              className="inputNormal"
              placeholder="Precio"
              autoComplete="off"
              ref={productImg2Ref}
              onChange={saveProductImg2}
            />
            {product.productImg2 === "" ? (
              <div>"No hay imagen registrada"</div>
            ) : (
              <div className="inputImgCont">
                <img
                  className="inputImg"
                  src={`${url}${product.productImg2}`}
                  alt="Imagen de Categoría"
                />
              </div>
            )}
          </div>
          <div className="input">
            <input
              type="file"
              name="productImg3"
              className="inputNormal"
              placeholder="Precio"
              autoComplete="off"
              ref={productImg3Ref}
              onChange={saveProductImg3}
            />
            {product.productImg3 === "" ? (
              <div>"No hay imagen registrada"</div>
            ) : (
              <div className="inputImgCont">
                <img
                  className="inputImg"
                  src={`${url}${product.productImg3}`}
                  alt="Imagen de Categoría"
                />
              </div>
            )}
          </div>
          <div className="input">
            <input
              type="file"
              name="productImg4"
              className="inputNormal"
              placeholder="Precio"
              autoComplete="off"
              ref={productImg4Ref}
              onChange={saveProductImg4}
            />
            {product.productImg4 === "" ? (
              <div>"No hay imagen registrada"</div>
            ) : (
              <div className="inputImgCont">
                <img
                  className="inputImg"
                  src={`${url}${product.productImg4}`}
                  alt="Imagen de Categoría"
                />
              </div>
            )}
          </div>
          {spinner ? (
            <>
              <ClipLoader color="#CEA168" loading size={40} />
              Actualizando producto
            </>
          ) : (
            ""
          )}
          <div className={showError ? "warning true" : "warning"}>
            <GppBadIcon />
            {
              "Error al actualizar producto verifica que la información sea correcta"
            }
          </div>
          <div onClick={saveProduct}>
            <Button text="Editar Producto" nameClass="btnBig" />
          </div>
        </>
      )}
    </div>
  );
};

export default EditProducts;

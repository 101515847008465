import React from "react";
import Adminheader from "../../components/adminHeader/AdminHeader";
import Button from "../../components/button/Button";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className="adminLogin">
      <Adminheader title="Menú Administrativo" />
      <Link to="/categorias">
        <Button text="Categorías" nameClass="btnMenu" />
      </Link>
      <Link to="/menuProductos">
        <Button text="Productos" nameClass="btnMenu" />
      </Link>
      <Link to="/menuClientes">
        <Button text="Clientes" nameClass="btnMenu" />
      </Link>
      <Link to="/portada">
        <Button text="Portada" nameClass="btnMenu" />
      </Link>
      <Link to="/productosDestacados">
        <Button text="Productos Destacados" nameClass="btnMenu" />
      </Link>
    </div>
  );
};

export default Menu;

import React from "react";
import NavBar from "./NavBar";
import Hero from "./Hero";

const Header = () => {
  return (
    <div className="header">
      <NavBar />
      <Hero />
    </div>
  );
};

export default Header;

import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

const ProductItem = ({
  name,
  description,
  price,
  discount,
  discountPor,
  img,
  id,
  initDate,
  finalDate,
}) => {
  const financial = (num) => {
    return Number.parseFloat(num).toFixed(2);
  };
  const calculateDiscount = (price, discount) => {
    let dis = (discount / 100) * price;
    return price - dis;
  };

  const compareDate = (initDate, finalDate) => {
    if (initDate && finalDate) {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let actualDate = new Date(year, month, day);
      let initDateArr = initDate.split("-");
      let initDay = initDateArr[2];
      let initMonth = initDateArr[1];
      let initYear = initDateArr[0];
      let finalDateArr = finalDate.split("-");
      let finalDay = finalDateArr[2];
      let finalMonth = finalDateArr[1];
      let finalYear = finalDateArr[0];
      let initActualDate = new Date(initYear, initMonth, initDay);
      let finalActualDate = new Date(finalYear, finalMonth, finalDay);
      if (actualDate >= initActualDate && actualDate <= finalActualDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <Link to={`/productoIndividual/${id}`} className="productItem">
      {compareDate(initDate, finalDate) ? (
        <div className="productItem-discount">Oferta</div>
      ) : (
        ""
      )}
      <div className="productItem-imgCont">
        <img className="productItem-imgCont__img" src={img} alt="product" />
      </div>
      <div className="productItem-infoCont">
        <div className="productItem-infoCont__title">{name}</div>
        <div className="productItem-infoCont__descr">{description}</div>
        <div className="productItem-infoCont__section">
          {compareDate(initDate, finalDate) ? (
            <>
              <div className="productItem-infoCont__sectionPriceCross">
                ${financial(price)}
              </div>
              <div className="productItem-infoCont__sectionPriceDiscount">
                ${financial(calculateDiscount(price, discountPor))}
              </div>
            </>
          ) : (
            <div className="productItem-infoCont__sectionPrice">
              ${financial(price)}
            </div>
          )}
          <div className="productItem-infoCont__sectionIcon">
            <VisibilityIcon style={{ fontSize: "12px" }} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductItem;

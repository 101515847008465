import React from "react";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Button from "../../../components/button/Button";
import { Link } from "react-router-dom";

const MenuClients = () => {
  return (
    <div className="adminLogin">
      <Adminheader title="Menú Clientes" />
      <Link to="/clientesPrincipal">
        <Button text="Clientes" nameClass="btnMenu" />
      </Link>
    </div>
  );
};

export default MenuClients;

import React from "react";
import Adminheader from "../../components/adminHeader/AdminHeader";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";

const AdminLogin = () => {
  return (
    <div className="adminLogin">
      <Adminheader title="Login" />
      <Input
        inputType="text"
        inputName="Usuario"
        inputClass="inputNormal"
        title="Usuario"
        message="Usuario"
        autoCompleteCtr="on"
      />
      <Input
        inputType="password"
        inputName="Contraseña"
        inputClass="inputNormal"
        title="Contraseña"
        message="Contraseña"
        autoCompleteCtr="on"
      />
      <Button text="Iniciar Sesión" nameClass="btnBig" />
    </div>
  );
};

export default AdminLogin;

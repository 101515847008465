import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/App';
import { Provider } from 'react-redux';
import Store from './app/store';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store = {Store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
</Provider>
);
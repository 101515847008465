import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-title">Contactanos</div>
      <div className="footer-text">352 123 4556</div>
      <div className="footer-text">lunaJoyeria@gmail.com</div>
      <div className="footer-text">Direccion</div>
      <div className="footer-text">
        <InstagramIcon />
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { deleteCategory } from "../../Api/Categories";
import { deleteProduct } from "../../Api/Products";
import { deleteFrontPage } from "../../Api/FrontPage";
import { MAIN_API_KEY } from "../../Api/API_KEYS";

const AdminItemMenu = ({
  text,
  itemType,
  clientInfo,
  textSec,
  itemId,
  type,
  idFront,
  table,
  urlCate,
  clientName,
  clientPhone,
  clientMail,
  clientMessage,
  clientProduct,
}) => {
  const url = `http://localhost:8080/category`;
  let urlFront = "";

  return (
    <>
      {itemType === "info" ? (
        <div className="adminItemMenu">
          <div className="adminItemMenu-title">{text}</div>
          <div className="adminItemMenu-opntionCont">
            <div
              className="adminItemMenu-option"
              onClick={() => {
                deleteFrontPage(
                  `${MAIN_API_KEY}/frontPage/deleteFrontPage.php/?id=${idFront}`
                );
              }}
            >
              {textSec}
            </div>
          </div>
        </div>
      ) : itemType === "info2" ? (
        <div className="adminItemMenu">
          <div className="adminItemMenu-title">{text}</div>
          <div className="adminItemMenu-opntionCont">
            <div
              className="adminItemMenu-option"
              onClick={() => {
                deleteFrontPage(
                  `${MAIN_API_KEY}/topProduct/deleteTopProduct.php/?id=${idFront}`
                );
              }}
            >
              {textSec}
            </div>
          </div>
        </div>
      ) : itemType === "clientInfo" ? (
        <div className="adminItemMenuSec">
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Nombre:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientName}</div>
            </div>
          </div>
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Telefono:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientPhone}</div>
            </div>
          </div>
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Email:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientMail}</div>
            </div>
          </div>
        </div>
      ) : itemType === "clientInfoMessage" ? (
        <div className="adminItemMenuSec">
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Nombre:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientName}</div>
            </div>
          </div>
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Telefono:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientPhone}</div>
            </div>
          </div>
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Email:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientMail}</div>
            </div>
          </div>
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Producto:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientProduct}</div>
            </div>
          </div>
          <div className="adminItemMenu-cont">
            <div className="adminItemMenu-cont__title">Mensaje:</div>
            <div className="adminItemMenu-cont__opntionCont">
              <div className="adminItemMenu-cont__option">{clientMessage}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="adminItemMenu">
          <div className="adminItemMenu-title">{text}</div>
          <div className="adminItemMenu-opntionCont">
            {type === "product" ? (
              <Link
                to={`/editarProductos/${itemId}`}
                className="adminItemMenu-option"
              >
                Editar
              </Link>
            ) : (
              <Link
                to={`/editarCategoria/${itemId}`}
                className="adminItemMenu-option"
              >
                Editar
              </Link>
            )}
            {type === "product" ? (
              <div
                className="adminItemMenu-option"
                onClick={() => {
                  deleteProduct(
                    `${MAIN_API_KEY}/products/deleteProduct.php/?id=${itemId}`
                  );
                }}
              >
                Eliminar
              </div>
            ) : (
              <div
                className="adminItemMenu-option"
                onClick={() => {
                  deleteCategory(urlCate + `/deleteCategory.php/?id=${itemId}`);
                }}
              >
                Eliminar
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminItemMenu;

import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Button from "../../../components/button/Button";
import { Link } from "react-router-dom";
import { useGetCategories } from "../../../Api/Categories";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const MenuProducts = () => {
  const categories = useGetCategories(
    `${MAIN_API_KEY}/category/readCategory.php`
  );
  return (
    <div className="adminLogin">
      <Adminheader title="Menú Productos" />
      {categories.length === 0 ? (
        <ClipLoader color="#CEA168" loading size={40} />
      ) : (
        categories.map((category, index) => {
          return (
            <div key={index}>
              <Link to={`/productosPrincipal/${category.categoryName}`}>
                <Button text={category.categoryName} nameClass="btnMenu" />
              </Link>
            </div>
          );
        })
      )}
    </div>
  );
};

export default MenuProducts;

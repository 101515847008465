import React from "react";

const Title = ({ text, kind }) => {
  /*
  Title Types
  -inLineText
  -underLineText
  */
  return (
    <div className="title">
      {kind === "inLineText" ? (
        <hr className={kind} dataContent={text}></hr>
      ) : (
        <div className={kind}>{text}</div>
      )}
    </div>
  );
};

export default Title;

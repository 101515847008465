import React, { useState, useEffect } from "react";
import GppBadIcon from "@mui/icons-material/GppBad";
import { useNavigate } from "react-router-dom";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Button from "../../../components/button/Button";
import { addFrontPage } from "../../../Api/FrontPage";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const TopProductsAddAd = () => {
  const [productImg1, setProductImg1] = useState("");
  const [frontItem, setFrontItem] = useState({});
  const [showError, setShowError] = useState(false);
  const navigation = useNavigate();

  const getValueSeleted = (e) => {
    setProductImg1(e.target.files[0]);
  };

  const addFrontItem = () => {
    if (productImg1 == "") {
      setShowError(true);
    } else {
      addFrontPage(
        `${MAIN_API_KEY}/frontPage/createFrontPage.php`,
        frontItem
      ).then((response) => {
        if (response.data === 200) {
          navigation(-1);
        }
      });
    }
  };

  useEffect(() => {
    setFrontItem({
      idProduct: "",
      frontImg: productImg1,
      frontType: "anuncio",
    });
  }, [productImg1]);

  return (
    <div className="adminLogin">
      <Adminheader title="Agregar Anuncio" />
      <div className="input">
        <div className="inputTitle">Seleccione la imagen del anuncio</div>
        <input
          type="file"
          name="productImg1"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          onChange={getValueSeleted}
        />
      </div>
      <div onClick={addFrontItem}>
        <Button text="Agregar Producto" nameClass="btnBig" />
      </div>
      <div className={showError ? "warning true" : "warning"}>
        <GppBadIcon />
        Selecciona una imagen
      </div>
    </div>
  );
};

export default TopProductsAddAd;

import React, { useState, useRef } from "react";
import GppBadIcon from "@mui/icons-material/GppBad";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import { addCategory } from "../../../Api/Categories";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const AddCategory = () => {
  const navigation = useNavigate();
  const [newCategory, setNewCategory] = useState("");
  const [categoryImg, setCategoryImg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const url = `${MAIN_API_KEY}/category/createCategory.php`;
  const categoryRef = useRef(null);
  const categoryImgRef = useRef(null);
  const saveCategory = (e) => {
    const { name, value } = e.target;
    setNewCategory(value);
  };

  const saveCategoryImg = (e) => {
    setCategoryImg(e.target.files[0]);
  };

  const saveNewCategory = () => {
    if (categoryImg) {
      let data = {
        categoryName: newCategory,
        categoryImg: categoryImg,
      };
      setSpinner(true);
      addCategory(url, data).then((response) => {
        if (response.data === 200) {
          setSpinner(false);
          navigation(-1);
        } else {
          setSpinner(false);
          setError(true);
          setShowError(true);
        }
      });
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="adminLogin">
      <Adminheader title="Agregar Categoría" />
      <div className="input">
        <div className="inputTitle">Nombre de la categoría</div>
        <input
          type="text"
          name="category"
          className="inputNormal"
          placeholder="Categoría"
          autoComplete="on"
          ref={categoryRef}
          onChange={saveCategory}
        />
      </div>
      <div className="input">
        <div className="inputTitle">
          {`Imagen de la Categoría`}
          <br />
          {`\( formatos png, jpg, jpeg o webp\)`}
        </div>
        <input
          type="file"
          name="categoryImg"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={categoryImgRef}
          onChange={saveCategoryImg}
        />
      </div>
      <div onClick={saveNewCategory}>
        <Button text="Agregar Categoría" nameClass="btnBig" />
      </div>
      {spinner ? (
        <>
          <ClipLoader color="#CEA168" loading size={40} />
          Agregando Categoría
        </>
      ) : (
        ""
      )}
      <div className={showError ? "warning true" : "warning"}>
        <GppBadIcon />
        {error
          ? "Verifica que el formato de la imagen sea jpg, jpeg, png o webp"
          : "Agregar una imagen para la categoría"}
      </div>
    </div>
  );
};

export default AddCategory;

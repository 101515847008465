import React,{useEffect} from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import GeneralProducts from '../containers/GeneralProducts';
import Home from '../containers/Home';
import IndividualCont from '../containers/IndividualCont';
import ContactForm from '../components/contactForm/ContactForm';
import AdminLogin from '../containers/adminScreens/AdminLogin';
import Menu from '../containers/adminScreens/Menu';
import MainCategory from '../containers/adminScreens/categories/MainCategory';
import AddCategory from '../containers/adminScreens/categories/AddCategory';
import EditCategory from '../containers/adminScreens/categories/EditCategory';
import MenuProducts from '../containers/adminScreens/products/MenuProducts';
import MainProducts from '../containers/adminScreens/products/MainProducts';
import AddProducts from '../containers/adminScreens/products/AddProducts';
import EditProducts from '../containers/adminScreens/products/EditProducts';
import MenuClients from '../containers/adminScreens/clients/MenuClients';
import MainClients from '../containers/adminScreens/clients/MainClients';
import InfoClients from '../containers/adminScreens/clients/InfoClients';
import MessagesClient from '../containers/adminScreens/clients/MessagesClient';
import HeroProductsMain from '../containers/adminScreens/especialSections/HeroProductsMain';
import HeroProductsAdd from '../containers/adminScreens/especialSections/HeroProductsAdd';
import TopProdutsMain from '../containers/adminScreens/especialSections/TopProdutsMain';
import TopProductsAdd from '../containers/adminScreens/especialSections/TopProductsAdd';
import TopProductsAddAd from '../containers/adminScreens/especialSections/TopProductsAddAd';
import Dsicounts from '../containers/Discounts';

const App = () => {
  useEffect(() => {
    document.title = "Luna Joyería";
  }, []);
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/productos/:categoria" element={<GeneralProducts />} />
      <Route exact path="/productoIndividual/:id" element={<IndividualCont />} />
      <Route exact path="/formularioContacto" element={<ContactForm />} />
      <Route exact path="/productosDescuento" element={<Dsicounts />} />
      <Route exact path="/formularioContacto/:producto/:id" element={<ContactForm />} />
      <Route exact path="/adminLogin" element={<AdminLogin />} />
      <Route exact path="/adminMenu" element={<Menu />} />
      <Route exact path="/categorias" element={<MainCategory />} />
      <Route exact path="/agregarCategoria" element={<AddCategory />} />
      <Route exact path="/editarCategoria/:id" element={<EditCategory />} />
      <Route exact path="/menuProductos" element={<MenuProducts />} />
      <Route exact path="/productosPrincipal/:categoria" element={<MainProducts />} />
      <Route exact path="/agregarProductos/:categoria" element={<AddProducts />} />
      <Route exact path="/editarProductos/:id" element={<EditProducts />} />
      <Route exact path="/menuClientes" element={<MenuClients />} />
      <Route exact path="/clientesPrincipal" element={<MainClients />} />
      <Route exact path="/clientesInformacion/:id" element={<InfoClients />} />
      <Route exact path="/mensajesClientes/:id" element={<MessagesClient />} />
      <Route exact path="/portada" element={<HeroProductsMain />} />
      <Route exact path="/portadaAgregar" element={<HeroProductsAdd />} />
      <Route exact path="/portadaAgregarAnuncio" element={<TopProductsAddAd />} />
      <Route exact path="/productosDestacados" element={<TopProdutsMain />} />
      <Route exact path="/productosDestacadosAgregar" element={<TopProductsAdd />} />
    </Routes>
  </BrowserRouter>
  )
}

export default App
import React from "react";
import { Link } from "react-router-dom";

const CategoryItem = ({ text, image, kind, gradient }) => {
  return (
    <div className="categoryItem">
      <div className={"categoryItem-cont " + kind}>
        <Link
          to={`/productos/${text}`}
          className={"categoryItem-textCont " + gradient}
        >
          {text}
        </Link>
        <div className="categoryItem-imgCont">
          <img
            className="sliderImg"
            src={`https://lunajoyeria.com.mx/luna/newMethod/category/${image}`}
            alt="LunaLogo"
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;

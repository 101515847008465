import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import GppBadIcon from "@mui/icons-material/GppBad";
import Adminheader from "../../../components/adminHeader/AdminHeader";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import { addProduct } from "../../../Api/Products";
import { MAIN_API_KEY } from "../../../Api/API_KEYS";

const AddProducts = () => {
  const [product, setProduct] = useState({});
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImg1, setProductImg1] = useState("");
  const [productImg2, setProductImg2] = useState("");
  const [productImg3, setProductImg3] = useState("");
  const [productImg4, setProductImg4] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const productNameRef = useRef(null);
  const productCategoryRef = useRef(null);
  const productDescriptionRef = useRef(null);
  const productPriceRef = useRef(null);
  const productImg1Ref = useRef(null);
  const productImg2Ref = useRef(null);
  const productImg3Ref = useRef(null);
  const productImg4Ref = useRef(null);
  let { categoria } = useParams();
  const navigation = useNavigate();
  const url = `${MAIN_API_KEY}/products/createProduct.php`;
  const saveProduct = () => {
    setSpinner(true);
    addProduct(url, product).then((response) => {
      if (response.data == 200) {
        setSpinner(false);
        navigation(-1);
      } else {
        setSpinner(false);
        setShowError(true);
      }
    });
  };

  const saveProductName = (e) => {
    const { name, value } = e.target;
    setProductName(value);
  };
  const saveProductDescription = (e) => {
    const { name, value } = e.target;
    setProductDescription(value);
  };
  const saveProductPrice = (e) => {
    const { name, value } = e.target;
    setProductPrice(value);
  };
  const saveProductImg1 = (e) => {
    setProductImg1(e.target.files[0]);
  };
  const saveProductImg2 = (e) => {
    setProductImg2(e.target.files[0]);
  };
  const saveProductImg3 = (e) => {
    setProductImg3(e.target.files[0]);
  };
  const saveProductImg4 = (e) => {
    setProductImg4(e.target.files[0]);
  };

  useEffect(() => {
    productCategoryRef.current.value = categoria;
  }, []);

  useEffect(() => {
    let img1;
    let img2;
    let img3;
    let img4;

    if (productImg1) {
      img1 = productImg1;
    } else {
      img1 = "";
    }
    if (productImg2) {
      img2 = productImg2;
    } else {
      img2 = "";
    }
    if (productImg3) {
      img3 = productImg3;
    } else {
      img3 = "";
    }
    if (productImg4) {
      img4 = productImg4;
    } else {
      img4 = "";
    }
    setProduct({
      productName: productName,
      productCategory: categoria,
      productDescription: productDescription,
      productPrice: productPrice,
      productDiscount: "",
      initDiscountDate: "",
      finalDiscountDate: "",
      productImg1: img1,
      productImg2: img2,
      productImg3: img3,
      productImg4: img4,
      METHOD: "POST",
    });
  }, [
    productName,
    productDescription,
    productPrice,
    productImg1,
    productImg2,
    productImg3,
    productImg4,
  ]);
  return (
    <div className="adminLogin">
      <Adminheader title="Agregar Producto" />
      <div className="input">
        <div className="inputTitle">Nombre del producto</div>
        <input
          type="text"
          name="productName"
          className="inputNormal"
          placeholder="Producto"
          autoComplete="off"
          ref={productNameRef}
          onChange={saveProductName}
        />
      </div>
      <div className="input">
        <div className="inputTitle">Categoría del producto</div>
        <input
          type="text"
          name="productCategory"
          className="inputNormal"
          placeholder="Categoría"
          autoComplete="off"
          ref={productCategoryRef}
          readOnly={true}
        />
      </div>
      <div className="input">
        <div className="inputTitle">Descripción del producto</div>
        <input
          type="text"
          name="productDescription"
          className="inputNormal"
          placeholder="Descripción"
          autoComplete="off"
          ref={productDescriptionRef}
          onChange={saveProductDescription}
        />
      </div>
      <div className="input">
        <div className="inputTitle">Precio del producto</div>
        <input
          type="text"
          name="productPrice"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={productPriceRef}
          onChange={saveProductPrice}
        />
      </div>
      <div className="input">
        <div className="inputTitle">
          {" "}
          {`Fotos del Producto`}
          <br />
          {`\( formatos png, jpg, jpeg o webp\)`}
        </div>
        <input
          type="file"
          name="productImg1"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={productImg1Ref}
          onChange={saveProductImg1}
        />
      </div>
      <div className="input">
        <input
          type="file"
          name="productImg2"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={productImg2Ref}
          onChange={saveProductImg2}
        />
      </div>
      <div className="input">
        <input
          type="file"
          name="productImg3"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={productImg3Ref}
          onChange={saveProductImg3}
        />
      </div>
      <div className="input">
        <input
          type="file"
          name="productImg4"
          className="inputNormal"
          placeholder="Precio"
          autoComplete="off"
          ref={productImg4Ref}
          onChange={saveProductImg4}
        />
      </div>

      {spinner ? (
        <>
          <ClipLoader color="#CEA168" loading size={40} />
          Agregando Producto
        </>
      ) : (
        ""
      )}
      <div className={showError ? "warning true" : "warning"}>
        <GppBadIcon />
        {"Error al agregar producto verifica que la información sea correcta"}
      </div>
      <div onClick={saveProduct}>
        <Button text="Agregar Producto" nameClass="btnBig" />
      </div>
    </div>
  );
};

export default AddProducts;

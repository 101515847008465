import React from "react";
/*
inputNormal
*/
const Input = ({
  inputType,
  inputName,
  inputClass,
  title,
  message,
  autoCompleteCtr,
  value,
  ref,
}) => {
  return (
    <div className="input">
      <div className="inputTitle">{title}</div>
      <input
        type={inputType}
        name={inputName}
        className={inputClass}
        placeholder={message}
        autoComplete={autoCompleteCtr}
        value={value}
        ref={ref}
      />
    </div>
  );
};

export default Input;

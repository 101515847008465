import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/footer/Footer";
import IndividualProduct from "../components/products/IndividualProduct";
import TopProducts from "../components/topPoducts/TopProducts";

const IndividualCont = () => {
  return (
    <div>
      <Header />
      <IndividualProduct />
      <TopProducts />
      <Footer />
    </div>
  );
};

export default IndividualCont;
